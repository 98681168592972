@font-face {
  font-family: 'Quick Sand';
  font-weight: 400;
  src: url('../fonts/Quicksand-Regular.woff') format('woff'),
  url('../fonts/Quicksand-Regular.ttf') format('ttf'),
  url('../fonts/Quicksand-Regular.eot') format('eot');
}

@font-face {
  font-family: 'Quick Sand';
  font-weight: 500;
  src: url('../fonts/Quicksand-Medium.woff') format('woff'),
  url('../fonts/Quicksand-Medium.ttf') format('ttf'),
  url('../fonts/Quicksand-Medium.eot') format('eot');
}

@font-face {
  font-family: 'Quick Sand';
  font-weight: 600;
  src: url('../fonts/Quicksand-Semi-Bold.woff') format('woff'),
  url('../fonts/Quicksand-Semi-Bold.ttf') format('ttf'),
  url('../fonts/Quicksand-Semi-Bold.eot') format('eot');
}

@font-face {
  font-family: 'Quick Sand';
  font-weight: 700;
  src: url('../fonts/Quicksand-Bold.woff') format('woff'),
  url('../fonts/Quicksand-Bold.ttf') format('ttf'),
  url('../fonts/Quicksand-Bold.eot') format('eot');
}
