@import '../../../assets/styles/variables';


// .show > .btn-primary.dropdown-toggle {
//   background-color: $white;
//   color: $text-color !important;
//   border: none;

// }

.show.hello {
  box-shadow: none;
  min-width: 0;
  padding: 0;
  margin: 0;
  left: unset !important;
  right: -60px;
  transform: translate3d(0, 40px, 0) !important;
}


.rdrCalendarWrapper {
  // background-color: aqua;
  border-radius: 16px !important;
}

.dropdown-menu.show {
  border-radius: 14px;
  box-shadow: 0px 2px 4px -2px rgba(74, 21, 75, 0.06), 0px 4px 8px -2px rgba(74, 21, 75, 0.1);
}

.date_picker_container {
  border-radius: 8px;
}

//.date-picker__apply-btn {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  font-size: 14px;
//  color: $primary;
//  border: 1px solid $primary;
//
//  &:hover, &:active, &:focus {
//    background: $primary;
//    color: $white;
//    border: 1px solid $primary;
//    box-shadow: none;
//  }
//
//  &:active {
//    background-color: $white !important;
//    color: initial;
//  }
//}
