@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.async-select {
  width: 100%;

  [class$='-container'] {
    width: 100%;
  }

  [class$='-menu'] {
    ::-webkit-scrollbar {
      width: 7px;
      background-color: #F5F5F5;

    }

    ::-webkit-scrollbar-thumb {
      background-color: #EBEAEB;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(225, 225, 225, 0.3);
      background-color: #ffffffda;
    }

    background-color: $white !important;
    @include shadow();
    border: none !important;
    font-size: 12px;
    font-weight: 600;
    color: $secondary;
    padding: 10px;
    border-radius: 7px;

    &:active,
    &:focus {
      box-shadow: none !important;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      color: $secondary;
    }
  }

  [class$='-control'] {
    border-color: $input-border-color !important;
    box-shadow: none !important;
    border-radius: 8px;
    font-size: 0.9rem;
    height: 100%;
    cursor: pointer;
    padding-block: 2px;

  }
  &.application {
  [class$='-control'] {
   border: none;
  }}

  &.customBorder {
    [class$='-control'] {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }}

  [class$='-indicatorContainer'] {
    padding: 5px 10px 5px 5px;
  }

  [class$='-ValueContainer'] {
    padding: 2px 2px 2px 10px;
  }

  [class$='-placeholder'] {
    color: $input-placeholder-color;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 85%;
  }

  [class$='-indicatorSeparator'] {
    display: none;
  }

  [class$='-multiValue'] {
    border-radius: 6px;
    background-color: #E0F2F1;
    padding: 0 4px;
    font-weight: 600;
    font-size: 12px;
    margin-right: 5px;

    [role=button] {
      padding-right: 2px;

      &:hover {
        color: $secondary;
      }
    }

    div:last-child:hover {
      background-color: transparent;
    }

    &:nth-child(even) {
      background: #FCF1E9;
    }

  }

  &.is-invalid {
    [class$='-control'] {
      border-color: $danger !important;
    }
  }

  //&.is-valid {
  //  [class$='-control'] {
  //    border-color: $success !important;
  //  }
  //}

  &.pay{
    width: max-content;
  }

 
  
  &.is-light-pink {
    
    [class$='-control'] {
      border-color: transparent;
      font-weight: 600;
      background-color: $primary-light !important;
      color: $primary !important;
      

    }

    [class$='-indicatorContainer'] {
      color: $primary !important;
    }
  }
}


