@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.openSidebar {
  min-width: 258px !important;
  color: #b8b6b7;
  transition: 0.5s;
}

.openSidebarContainer {
  min-width: 258px !important;
  color: #b8b6b7;
  transition: 0.5s;
}

.closeSidebar {
  width: 70px !important;
  color: #b8b6b7;
  transition: 0.5s;
}

@keyframes textAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.MenuText {
  transition: 0.4s;
  animation-name: textAnimation;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.link:hover {
  color: white;
}

.menuButton:hover {
  background-color: #00000048;

}

