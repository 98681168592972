@import './variables';
@import 'fonts';
@import './mixins';
@import '~bootstrap/scss/bootstrap';
@import '../../app/components/portlet/_portlet';
// @import '../../../src/features/calender/calender';
@import 'react-calendar/dist/Calendar.css';
@import '~react-date-range/dist/styles.css';
@import '~react-date-range/dist/theme/default.css';
@import '~react-toastify/dist/ReactToastify.min.css';

.cursor-pointer {
  cursor: pointer !important;
}

.app_container {
  width: calc(100% - 260px);
}

.flex-center {
  display: flex;
  align-items: center;
  justify-items: center;
}

.form-control {
  &.is-invalid,
  &.is-valid {
    background-image: none !important;
    padding-right: calc(0.5em + 0.14rem);
  }
}
.rendered-html ol,
.rendered-html ul,
.rendered-html p {
  margin-bottom: 0;
}
.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  height: 1.2em;
  white-space: nowrap;
}

.form-label {
  display: flex;
  align-items: center;
}

.feature-disable {
  pointer-events: none;
  opacity: 0.6;
}

.feature-pending {
  display: none !important;
}
.feature-pending-new {
  // display: none !important;
  pointer-events: none !important;
  opacity: 0.1;

}
.no-underline{
  text-decoration: none;
  color:black !important;
}

::-webkit-scrollbar {
  height: 7px;
  width: 7px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(225, 225, 225, 0.3);
  background-color: #ffffffda;
}

.search-input {
  font-size: 12px;
  overflow: hidden;
  position: relative;

  .search-icon {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    padding: 0;
    color: $white;
  }

  input {
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    width: 0;
    padding: 0;
    position: absolute;
    left: 12px;
    transition: all 0.5s linear;

    &:focus {
      outline: none;
    }
  }

  transition: width 0.5s linear;

  &.show-input {
    width: 230px;

    input {
      width: 180px;
      padding: 4px 10px;
    }
  }
}



hr {
  border-top: 1px solid $shadow-color-dark;
}

.border-radius-left-0{
 border-top-left-radius: 0 !important;
 border-bottom-left-radius: 0 !important;
}


.max-width-fit-content {
  max-width: fit-content !important;
}



  .progressbar {
    text-align: center;
    width:100%;
    zoom: 1.5;
  }
  .counter div {
    background-color: white;
    display: inline-block;
    border-radius: 50%;
    width:auto;
    line-height: 1.88em;
    vertical-align: top;
    z-index: 100;
    position: relative;
  }
  
  .counter td {
    cursor: pointer;
    text-align: center;
    position: relative;
    width: 100px;
  }
  @media screen and (max-width:1700px) {
    .td-text{
      width: max-content;
    }
  }
  @media screen and (max-width:1430px) {
    .td-text{
      width: auto !important;
    }
  }
  
  .counter span {
    position: absolute;
    top: 1.1em;
    left: 53%;
    width: 96%;
    border-top: 1.1px dotted #B8B7B8;
  }
  
  .progressbar .active
  ,.progressbar .active *{
    color: #585558;
    span {
      border-top: 1px solid #26A69A !important;
    }
  }
  
  .progressbar .descriptions {
    font-size: 7px;
  }
  .job-analysis-dropdown{
    overflow: scroll;
    max-height: 500px;
  }
  
  
  .candidate-analysis-dropdown{
    overflow: scroll;
    max-height: 200px;
  }
  
  .cand-analyiss{
    min-width: 250px;
    justify-content: space-between;
  }

  table > :not(caption) > * > * {
    background-color: transparent  !important;
  
    }
    .card, .modal-header{
      background:white !important;
    }
    .custom-drop-down.show .menu-toggle {
    color:#4a154b;
    }
    .custom-drop-down .menu-dropdown{
      background-color: white;
    }


    .ql-html-buttonCancel {
      font-size: 10px;
      color: #4A154B;
      border: none;
      background: transparent;
      border-radius: 12%;
      font-weight: 600;
      margin-right: 8px !important;
    }
    
    .ql-html-buttonOk {
      font-size: 10px;
      border-radius: 5px;
      color: white;
      font-weight: 600;
      padding: 5px 12px !important;
      border: none;
      background: #4A154B;
    }
    
    .ql-html-buttonGroup {
      position: absolute;
      bottom: -20px !important;
      transform: scale(1.5);
      left: calc(100% - 140px) !important;
    }




    @media (max-width: 991px) {
      .ql-html-popupContainer {
        top: 2% !important;
        left: 2% !important;
        right: 2% !important;
        bottom: 2% !important;
      }
    }
    
    /* Medium screens and above */
    @media (min-width: 992px) and (max-width: 1250px) {
      .ql-html-popupContainer {
        top: 10% !important;
        left: 15% !important;
        right: 15% !important;
        bottom: 10% !important;
      }
    }
    
    @media (min-width: 1251px) {
      .ql-html-popupContainer {
        top: 15% !important;
        left: 25% !important;
        right: 25% !important;
        bottom: 15% !important;
      }
    }
    
    .ql-html-textArea {
      border-radius: 8px;
    }
    .ql-html-popupTitle{
      font-weight: 600;
    }