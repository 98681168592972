@import '../../../assets/styles/variables';
@import "../../../assets/styles/mixins";

.pagination__container {
  list-style: none;
  display: flex;
  align-items: stretch;
  margin: 20px 0;
  padding: 0;
  font-weight: 600;

  li {
    display: flex;
  }

  &.circle {
    align-items: center;

    li {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .previous, .next {
      width: auto;
      margin: 0 5px;

      a {
        outline: none !important;
      }
    }
  }
}

li.break {
  padding: 2px;
  margin: 0 3px;
}

.pagination__page {
  padding: 6px 12px;
  margin: 0 3px;
  color: $text-color;
  border-radius: 8px;
  text-decoration: none;
  font-size: 12px;
  border: 1px solid $primary-light;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: none;
    background: $primary-light;
    color: $primary;

    svg {
      fill: $primary;
    }
  }
}

.pagination__page-prev-next {
  margin: 0 5px;

  a {
    @extend .pagination__page;
    margin: 0;
    background-color: $input-border-color;
    color: $headings-color;
  }

  &.disabled {
    a {
      cursor: not-allowed;
      background-color: $white;
      color: $input-plaintext-color;
    }
  }

  & svg {
    fill: currentColor;
  }
}

.pagination__page-circle {
  @extend .pagination__page;
  background: $primary;
  border-radius: 50%;
  padding: 0;
  margin: 0;

  &:hover {
    background: $body-bg;
    color: $text-color;
  }
}

.pagination__page-active {
  background: $primary-light !important;
  color: $primary;
}

.pagination__page-circle-active {
  background: $body-bg;
  color: $text-color;
}

.pagination__pages-showing {
  color: #b2aaaa;
  font-size: 12px;
}

.pagination__page-size {
  background-color: $text-color !important;
  color: $text-color !important;
  font-size: 12px !important;
}

.pagination__per-page {
  display: flex;
  align-items: center;

  .text {
    font-size: 12px;
    color: $text-color;
    font-weight: 600;
    margin-right: 10px;
  }

  .menu-toggle {
    background-color: $body-bg !important;
   
  }
}

