@import '../../../assets/styles/variables';
@import "../../../assets/styles/mixins";

.custom-drop-down {
  .menu-toggle {
    background-color: $primary-light;
    border: none !important;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $secondary;
    // padding: 10px;
    border-radius: 7px;
    //margin-left: -20px;
    &.kanban {
      margin-right: -20px;
    }


    &:active, &:focus {
      box-shadow: none !important;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      color: $secondary;
    }

    &:after {
      display: none !important;
    }
  }


  .menu-dropdown {
    border: 0 !important;
    padding: 0.5rem !important;
    margin: 4px 0px;
    border-radius: 8px;
    @include shadow();
    // inset: 10px 0px auto auto !important;


    &.kanban {
      margin-right: -30px;
    }


    &.adminJobs {
      margin-top: -10px;
    }

    &.mr-n12 {
      inset: 10px auto auto 10px !important;
    }


    .dropdown-divider {
      margin: 0;
      color: $light-text !important;
      border-color: $light-text;
      opacity: 0.1;
    }

    .dropdown-item {
      padding: 1rem 2rem 0.5rem 1.2rem !important;
      display: flex;
      align-items: center;
      font-weight: 600;
      color: $text-color;
      font-size: 12px;

      &:active {
        background-color: $hover-light !important;
        border-color: $hover-light !important;
      }


      &:focus {
        box-shadow: none !important;
      }

      &:hover {
        border-radius: 10px;
        background-color: $hover-light !important;
      }

    }

    // &:before {
    //   right: 7%;
    //   top: -5%;
    //   content: " ";
    //   height: 0;
    //   width: 0;
    //   position: absolute;
    //   pointer-events: none;
    //   border: 10px solid rgba(255, 255, 255, 0);
    //   border-bottom-color: #ffffff;
    //   margin-top: -10px;
    // }
  }

  &.show {
    .menu-dropdown {
      border-top-right-radius: 0px;
    }

    .menu-toggle {
      background-color: $primary-light;
    }
  }
}
