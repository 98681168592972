@import '../../../assets/styles/variables';
@import "../../../assets/styles/mixins";


.portlet {
  background: $white;
}

.portlet__header-container {
  padding: 30px 30px 0;


  .portlet__header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
  }

  @include media-breakpoint-down(sm) {
    padding: 20px;
  }

  &.isHeadingOnly {
    padding: 20px 30px;
  }
}


.portlet__header-border {
  padding: 30px 0 15px 0;
  margin: 0 30px;
  @include media-breakpoint-down(sm) {
    padding: 20px 0 15px 0;
    margin: 0 20px;
  }
  border-bottom: 1px solid $input-border-color;
}


.portlet__body {
  padding: 20px;
  border-radius: 14px;
  background-color: $white;
  @include media-breakpoint-down(sm) {
    padding: 15px;
  }
}

.portlet__body-bg {
  @extend .portlet__body;
  background-color: $body-bg;
  border-radius: 0;
}


.portlet__header-tabs {
  display: flex;
  align-items: center;
}

.portlet__header-tab {
  color: $light-text;
  margin-right: 35px;
  font-size: 13px;
  font-weight: 700;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 10px 0 10px 0;
  transition: border-bottom-color 0.3s ease-in;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  &:hover, &.tab-active {
    color: $primary;
    border-color: $primary;
  }

}
