$primary: #4A154B;
$primary-light: #F8F2F7;
$primary-light2: #CCB2C8;
$danger: #B80000;
$secondary: #2A2A2A;
$success: #6EAA64;
$white: #ffffff;
$font-family-base: 'Quick Sand';
$text-color: #585558;
$hover-light: #FAFAFA;
$light-text: #707070;
$text-muted: #787377;
$body-bg: #F5F5F5;

// extra

$separator: #e9ecf3;

$shadow-color: #eceff5;
$shadow-color-dark: #7d7d7d;
$gray-bg: #f9f8f8;
$light-bold-black: #767476;
$tag-color:#FCF1E9;
$monoBlue : #ECEEF4;

// Body
$body-color: #535870;

// text
$font-size-base: 0.8rem;
$headings-color: #0A0509;
$monoDarkBlue:#A7ACBB;


// Input
$input-btn-padding-y: .57rem !default;
$input-btn-padding-x: 1.2rem !default;
$input-border-color: #EBEAEB;
$input-placeholder-color: #9A9FA5;
$input-plaintext-color: $text-muted;
$input-disabled: $light-text;
$input-bg: $white;
$borders:#957CA0;

// Headings
$headings-font-family: $font-family-base;
$headings-font-weight: 600;

//Label
$form-label-font-weight: 600;
$form-label-color: $headings-color;
$form-label-font-size: 0.9rem;

// Button
$btn-font-weight: 600;
$btn-border-radius: 8px;

$tag-chips:#eeeeee;


$theme-colors: (
        'primary': $primary,
        'secondary': $secondary,
        'success' : $success,
        'white' : $white,
        'danger': $danger,
        'gray' : $input-border-color,
        'blue' : $monoBlue,
        'darkBlue':$monoDarkBlue,
        'lightBlack':$light-bold-black,
        'darkGray':$text-color,
        'purpleBorder':$borders
);
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px
) !default;
